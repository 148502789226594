//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.menuButton
{
    > span
    {
        color:       $colorBlack;
        transition:  background-color 0.25s ease,
                     color 0.25s ease,
                     font-size 0.25s ease;
        cursor:      pointer;
        position:    relative;
        padding:     12px 0 22px 0;
        font-weight: bold;
        font-size:   12px;
        display:     block;
        text-align:  left;

        &.withSubText
        {
            padding: 12px 0 30px 0;
        }

        i
        {
            margin-right: 5px;
            transition:   margin-right 0.25s ease,
                          color 0.25s ease;
        }

        span
        {
            font-size:     10px;
            display:       block;
            position:      absolute;
            top:           30px;
            left:          0;
            transition:    left 0.25s ease,
                           color 0.25s ease;
            overflow:      hidden;
            text-overflow: ellipsis;
            width:         150px;
            white-space:   nowrap;
            height:        18px;
        }

        &:hover,
        &.active,
        a:hover,
        a:active
        {
            background: $colorTransparent;
            color:      $colorRed !important;

            i
            {
                color: $colorRed !important;
            }

            span
            {
                color: $colorRed !important;
            }
        }

        a
        {
            text-decoration: none;
            color:           $colorBlack;
        }
    }
}
