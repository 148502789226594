//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.paragraph
{
    font-size:   13px;
    line-height: 20px;
    margin:      0 0 6px 0;
    width:       50%;

    &.paragraphFullWidth
    {
        width: auto;
    }

    &,
    a
    {
        color: $colorBlack !important;
    }
}
