//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.mainMenu
{
    padding: 40px 0 0 40px;

    h1
    {
        width:               35px;
        background-position: 0 0;
        background-repeat:   no-repeat;
        margin:              0;
        height:              350px;
        display:             flex;

        a
        {
            display:          block;
            height:           100%;
            width:            100%;
            color:            transparent;
            background-color: transparent;

            img
            {
                width:  25px;
                height: 281px;
            }
        }

        div
        {
            span
            {
                display:          flex;
                justify-content:  flex-end;
                width:            100%;
                background-color: transparent;
                writing-mode:     vertical-rl;
                padding-bottom:   15px;
                rotate:           180deg;
            }
        }
    }

    .copyright
    {
        padding:     0 42px 0 0;
        font-size:   12px;
        line-height: 15px;

        a
        {
            background-color: transparent !important;
            text-decoration:  none;
            font-weight:      bold;
            color:            $colorBlack !important;
            display:          block;
            margin:           2px 0 0 0;
            padding:          0 !important;
            font-size:        12px !important;
            transition:       color 0.25s ease;

            &:hover
            {
                color: $colorRed !important;
            }
        }
    }

    .slogan
    {
        font-size:     14px;
        line-height:   22px;
        font-weight:   400;
        margin-bottom: 0;
        color:         $colorTundora !important;
        width:         204px;

        @media (min-width: 1025px)
        {
            font-size: 16px;
        }

        &,
        *
        {
            font-family: $fontZektonRegular;
        }

        strong
        {
            font-size:   18px;
            font-weight: 300;
            font-family: $fontZektonRegular;

            @media (min-width: 1025px)
            {
                font-size: 20px;
            }

            span
            {

            }
        }
    }

    .socialMediaLinks
    {
        list-style: none;
        margin:     0;
        padding:    0;

        li
        {
            float:  left;
            margin: 0 6px 0 0;

            a
            {
                color:             $colorBlack;
                transition:        color 0.25s ease,
                                   transform 0.25s ease;
                text-decoration:   none;
                background-color:  transparent !important;
                background-repeat: no-repeat !important;
                background-size:   contain !important;
                padding:           0 !important;
                display:           block;
                width:             21px;
                height:            21px;
                outline:           none !important;
                box-shadow:        none !important;
                text-indent:       -9999px;
                overflow:          hidden;

                i
                {
                    margin:  0 !important;
                    display: none;
                }

                &:hover
                {
                    color:     $colorRed;
                    transform: rotate(-3deg) scale(1.1);
                }
            }

            &.socialMediaLinkFacebook
            {
                a
                {
                    background-image: url(../../../../assets/images/social-fb.png);
                }
            }

            &.socialMediaLinkLinkedIn
            {
                a
                {
                    background-image: url(../../../../assets/images/social-linkedin.png);
                }
            }

            &.socialMediaLinkXing
            {
                a
                {
                    background-image: url(../../../../assets/images/social-xing.png);
                }
            }
        }
    }

    .menuButtonWrapper
    {
        list-style: none;
    }
}
