//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.analysisInfo
{
    padding:     25px;
    font-size:   13px;
    line-height: 20px;
    text-align:  center;
    display:     block;
    width:       100%;

    strong
    {
        font-weight: bold;
        color:       $colorRed !important;
    }
}
