//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.longTextWrapper
{
    h3
    {
        color:       $colorRed;
        font-family: $fontZektonRegular;
        font-weight: 100;
        font-size:   30px;
        line-height: 26px;
    }

    h4
    {
        color:       $colorRed;
        font-family: $fontZektonRegular;
        font-weight: 100;
        font-size:   24px;
        line-height: 26px;
    }

    p,
    h3,
    h4
    {
        margin-bottom: 20px;
    }

    .textWrapper
    {
        column-count: 2;
    }

    ul
    {
        margin:     0 0 0 0;
        list-style: none;

        li
        {
            font-size:   13px;
            line-height: 20px;
            margin:      0 0 3px 0;

            &,
            a
            {
                color:           $colorBlack !important;
                text-decoration: none;
            }
        }
    }
}
