//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.globalDropZoneWrapper
{
    min-height: 100vh;

    .globalDropZone
    {
        background:     $colorDam;
        position:       absolute;
        top:            0;
        left:           0;
        width:          100%;
        height:         100%;
        z-index:        $zIndexGlobalDropZone;
        display:        flex;
        padding:        2%;
        opacity:        0;
        pointer-events: none;
        transition:     opacity 0.15s linear;

        &.globalDropZoneVisible
        {
            opacity:        1;
            pointer-events: all;
        }

        .globalDropZoneBorder
        {
            align-items:     center;
            justify-content: center;
            border:          2px dashed $colorRed;
            display:         flex;
            flex:            1;
            border-radius:   20px;
            text-align:      center;
            color:           $colorRed;

            .globalDropZoneInnerText
            {
                height:          100%;
                text-align:      center;
                display:         flex;
                justify-content: center;
                align-items:     center;
                flex-direction:  column;

                h3
                {
                    margin:      0 0 10px 0;
                    font-size:   22px;
                    font-family: $fontZektonRegular;
                }
            }
        }
    }
}
