//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.dropZone
{
    position:        relative;
    background:      $colorDam;
    border-radius:   25px;
    height:          350px;
    cursor:          pointer;
    display:         flex;
    justify-content: center;
    align-items:     center;

    h2
    {
        text-align:  center;
        color:       $colorBlack;
        font-size:   20px;
        line-height: 34px;
        font-weight: normal;
        font-family: $fontZektonRegular;
        padding:     0;
        margin:      0;
    }

    input
    {
        position: absolute;
        top:      0;
        left:     0;
        width:    100%;
        height:   100%;
        opacity:  0;
        cursor:   pointer;
    }

    .arrow
    {
        display:             block;
        position:            absolute;
        top:                 120px;
        right:               -680px;
        width:               622px;
        height:              69px;
        background:          url('../../../../assets/images/arrow.png') no-repeat;
        animation-name:      arrow-appear;
        animation-duration:  1s;
        animation-delay:     2s;
        animation-fill-mode: forwards;
    }
}

@keyframes arrow-appear
{
    from
    {
        right: -680px;
    }

    to
    {
        right: -350px;
    }
}
