//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.homeWrapper
{
    padding:         20px 20px 270px;
    background:      url(../../assets/images/seeds.png) no-repeat bottom right;
    background-size: 50%;

    .slogan
    {
        font-size:   20px;
        line-height: 28px;
        color:       $colorBlack;

        &,
        *
        {
            font-family: $fontZektonRegular;
            font-weight: normal;
        }

        .line
        {
            word-spacing:   3px;
            display:        inline-block;
            padding-bottom: 10px;
        }

        strong
        {
            font-size:   28px;
            font-weight: normal;

            span
            {

            }

            &.red
            {
                color: $colorRed;
            }
        }
    }

    .textWrapper
    {
        color:        $colorBlack !important;
        column-count: 2;

        hr
        {
            color:      $colorRed;
            background: $colorRed;
        }

        h2,
        h3,
        h4,
        h5,
        h6
        {
            color:       $colorRed;
            font-family: $fontZektonRegular;
            font-weight: 100;
        }

        h3
        {
            font-size: 30px;
        }

        h5
        {
            font-size: 20px;
        }

        a
        {
            color:           $colorBlack !important;
            transition:      color 0.25s ease;
            text-decoration: none !important;

            &:hover
            {
                color: $colorRed !important;
            }
        }

        ul
        {
            margin:     0;
            list-style: none;
            padding:    0 0 0 2px;
            font-size:  13px;
        }

        p
        {
            color:       $colorBlack !important;
            font-size:   13px;
            line-height: 20px;
            margin:      0 0 6px 0;
        }
    }
}
