//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// stylelint-disable-next-line primer/no-unused-vars
$fontsPath:             '../assets/fonts/';
$zektonFontPath:        $fontsPath + 'zekton/';
$zektonBoldFontPath:    $zektonFontPath + '2AC5C5_1_0';
$zektonRegularFontPath: $zektonFontPath + '2AC5C5_2_0';

@font-face
{
    font-family: 'ZektonRg-Bold';
    src:         url($zektonBoldFontPath + '.eot');
    src:         url($zektonBoldFontPath + '.eot?#iefix') format('embedded-opentype'),
                 url($zektonBoldFontPath + '.woff') format('woff'),
                 url($zektonBoldFontPath + '.ttf') format('truetype');
}

@font-face
{
    font-family: 'ZektonRg-Regular';
    src:         url($zektonRegularFontPath + '.eot');
    src:         url($zektonRegularFontPath + '.eot?#iefix') format('embedded-opentype'),
                 url($zektonRegularFontPath + '.woff') format('woff'),
                 url($zektonRegularFontPath + '.ttf') format('truetype');
}

$fontHelvetica:         'Helvetica', "Arial", sans-serif;
$fontZektonRegular:     'ZektonRg-Regular', sans-serif !important;
$fontZektonBold:        'ZektonRg-Bold', sans-serif !important;

:export
{
    fontHelvetica:     $fontHelvetica;
    fontZektonBold:    $fontZektonBold;
    fontZektonRegular: $fontZektonRegular;
}
