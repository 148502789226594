//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$colorBlack:       #42534b;
$colorBrown:       #42534b;
$colorDam:         #f4f2ee;
$colorDarkRed:     #58302f;
$colorGray:        #42534b;
$colorTundora:     #424242;
$colorRed:         #58302f;
$colorRed80:       rgba(88, 48, 47, 0.8);
$colorTransparent: transparent;
$colorWhite:       #ffffff;
$colorWhite80:     #ffffff;

:export
{
    colorBlack:       $colorBlack;
    colorBrown:       $colorBrown;
    colorDam:         $colorDam;
    colorDarkRed:     $colorDarkRed;
    colorGray:        $colorGray;
    colorTundora:     $colorTundora;
    colorRed:         $colorRed;
    $colorRed80:      $colorRed80;
    colorTransparent: $colorTransparent;
    colorWhite:       $colorWhite;
    colorWhite80:     $colorWhite80;
}
