//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.analyseWrapper
{
    .tooltipWrapper
    {
        margin: 0 -1px 0 5px;
    }

    .tableWrapper
    {
        overflow-x: auto;

        table
        {
            width:               100%;
            text-align:          left !important;
            border-top-color:    $colorDam !important;
            border-bottom-color: $colorDam !important;
            border:              0 !important;
            box-shadow:          none !important;
            background:          $colorTransparent !important;

            th,
            td
            {
                padding: 0 !important;
            }

            .paddingWrapper
            {
                padding:  0 20px 0 15px;
                position: relative;

                &.paddingWrapperCenterText
                {
                    text-align: center;
                }
            }

            thead
            {
                tr
                {
                    th
                    {
                        background:          $colorWhite;
                        color:               $colorRed;
                        text-align:          left !important;
                        border-top-color:    $colorDam !important;
                        border-bottom-color: $colorDam !important;
                        font-family:         "ZektonRg-Regular", sans-serif;
                        font-weight:         100;
                        font-size:           14px;
                        cursor:              pointer;
                        height:              55px !important;
                        vertical-align:      middle !important;
                        user-select:         none;

                        .icon
                        {
                            position:    absolute;
                            top:         21px;
                            margin-left: 6px;

                            &.iconAscending
                            {
                                color: $colorDam;
                                top:   6px;
                            }

                            &.iconDescending
                            {
                                top:     -2px;
                                display: none;
                            }
                        }

                        &.noSorting
                        {
                            cursor: default;
                        }

                        .sortingActive
                        {
                            .icon
                            {
                                color: $colorRed !important;
                            }

                            &.sortingDirectionAscending
                            {
                                .icon
                                {
                                    &.iconAscending
                                    {
                                        display: inline;
                                    }

                                    &.iconDescending
                                    {
                                        display: none;
                                    }
                                }
                            }

                            &.sortingDirectionDescending
                            {
                                .icon
                                {
                                    &.iconAscending
                                    {
                                        display: none;
                                    }

                                    &.iconDescending
                                    {
                                        display: inline;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tbody
            {
                tr
                {
                    &:hover,
                    &.is-selected
                    {
                        background: $colorDam !important;
                    }

                    td
                    {
                        color:               $colorBlack;
                        text-align:          left !important;
                        border-top-color:    $colorDam !important;
                        border-bottom-color: $colorDam !important;
                        min-height:          85px;
                        min-width:           120px;
                        height:              85px;
                        font-size:           12px;
                        line-height:         20px;
                        border-top-width:    0 !important;
                        white-space:         nowrap;

                        &.fullWidth
                        {
                            width: 100%;
                        }

                        .imageWrapper
                        {
                            display:         flex;
                            align-items:     center;
                            justify-content: flex-end;
                        }

                        .emptyImageWrapper
                        {
                            display:         flex;
                            align-items:     center;
                            justify-content: center;
                        }

                        .image
                        {
                            height:              60px;
                            width:               100%;
                            min-width:           60px;
                            background-color:    $colorTransparent;
                            background-size:     contain;
                            background-position: left center;
                            background-repeat:   no-repeat;
                            cursor:              pointer;
                            max-width:           180px;
                        }

                        span
                        {

                        }

                        .limit
                        {
                            display:       inline-block;
                            max-width:     200px;
                            text-overflow: ellipsis;
                            overflow:      hidden;
                            position:      relative;
                            top:           6px;
                            margin:        0 0 0 -3px;
                        }
                    }
                }
            }

            &.fixed
            {
                width:      100%;
                height:     50px;
                background: $colorWhite;
                z-index:    1337;

                thead
                {
                    position: fixed;
                    top:      0;

                    th
                    {
                        border-top:    20px solid $colorWhite !important;
                        border-bottom: 1px solid $colorDam !important;
                    }
                }
            }
        }
    }

}
