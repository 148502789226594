//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/all';

*
{
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

::selection,
::-moz-selection,
::-o-selection,
::-ms-selection,
::-webkit-selection
{
    background-color: $colorDam !important;
    color:            $colorWhite !important;
}

html
{
    min-height: 100%;
    height:     100%;
}

body
{
    margin:                  0;
    padding:                 0;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image:        url('./assets/images/chilli-with-seeds.png');
    background-repeat:       no-repeat;
    background-position:     50px bottom;
    min-height:              100%;
    background-size:         100px;
    font-family:             $fontHelvetica;
    font-size:               14px;
    font-weight:             400;
    line-height:             20px;
    overflow-x:              hidden;
}

#root
{
    min-height: 100%;
}

a,
button
{
    touch-action: manipulation;
}

.contentWrapper
{
    height:  50px;
    flex:    1;
    padding: 50px 20px 20px 20px;
}

.menuWrapper
{
    width: 240px;
}

.textWrapper
{

}

.wrapper
{
    display:        flex;
    flex-direction: row;
}
